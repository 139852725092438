import React from "react";
import { Link } from "react-router-dom";
import CatalogoComponentesLista from './componentes/listado';
import { Panel } from 'primereact/panel';
import CheckPermissions from "controllers/permissions/services";


class CatalogoComponentes extends React.Component {
	constructor(props){
		super(props)
		this.headerTemplate = this.headerTemplate.bind(this)
		this.permissionService = new CheckPermissions();
	}

	agregar(){
		this.props.history.push({
		 pathname:'/app/mantenimiento/componentes/agregar'
	 });
	}
	headerTemplate()
	{
		return (<div className="p-panel-header flex-wrap  d-flex">
			<div>
				Catálogo De Componentes
			</div>
			<div>
			{this.permissionService.checkPermission('mantenimiento_componentes_agregar') && <Link to="/app/mantenimiento/componentes/agregar" className="btn btn-primary">
				<i className="pi pi-plus mr-2 v-middle" /> Agregar Componente
			</Link>}
			</div>
		</div>);
	}
	render() {
		return (
			<div className="container-fluid">
				<Panel headerTemplate={this.headerTemplate}>
					<CatalogoComponentesLista history={this.props.history}/>
				</Panel>
			</div>
		);
	}
}

export default CatalogoComponentes;
