import React from "react";
import { Row,Col} from 'reactstrap';
import { Toast } from 'primereact/toast';
import moment from 'moment';
import MantenimientoService from  'controllers/mantenimiento/mantenimiento-service';
import { InputNumber } from 'primereact/inputnumber';
import PCalendar from '../../../ui-components/calendar';
import {Button} from 'primereact/button';
import { Panel } from 'primereact/panel';
import {url} from '../../../../constants';


let fileState;
class CrearReporteFalla extends React.Component {
	constructor(props){
		super(props);
		this.state={

		}
		this.servicio = new MantenimientoService();
		this.getCommonDataFromChilds = this.getCommonDataFromChilds.bind(this);

		this.handleSubmit = this.handleSubmit.bind(this);
	}


	componentDidMount() {
	  let values = this.props.location.state;
	  if(values){
		fileState = {name:{cotizaciones: values?.cotizaciones}}
	    this.setState({
				proveedor: values.proveedor,
				remision: values.remision,
				factura: values.factura,
				condiciones: values.condiciones,
				unidad: values.unidad,
				fecha:values.fecha,
				cantidad: values.cantidad,
				id:values.id,
				sobreescribir: true,
				...fileState
	    });
	  }

	}

	handleSubmit(event) {
				event.preventDefault();

				let that = this;

				let datos ={};
				const data = new FormData();
				datos.proveedor = this.state.proveedor;
				datos.remision = this.state.remision;
				datos.factura = this.state.factura;
				datos.condiciones = this.state.condiciones;
				datos.unidad = this.state.unidad;
				datos.cantidad = this.state.cantidad;
				datos.fecha = this.state.fecha

				data.append("cotizaciones",this.state.cotizaciones);

				let mensajes = [];

				if(!this.state.proveedor){
						mensajes.push({severity: 'error', summary: 'Información incompleta', detail: 'Ingrese un proveedor'});
				}
				if(!this.state.remision){
						mensajes.push({severity: 'error', summary: 'Información incompleta', detail: 'Ingrese la remisión'});
				}
				if(!this.state.factura){
						mensajes.push({severity: 'error', summary: 'Información incompleta', detail: 'Ingrese la factura'});
				}
				if(!this.state.condiciones){
						mensajes.push({severity: 'error', summary: 'Información incompleta', detail: 'Ingrese las condiciones'});
				}
				if(!this.state.unidad){
						mensajes.push({severity: 'error', summary: 'Información incompleta', detail: 'Seleccione la unidad'});
				}

				if(!this.state.cantidad){
						mensajes.push({severity: 'error', summary: 'Información incompleta', detail: 'Ingrese la cantidad'});
				}

				if(mensajes.length){
					this.growl.show(mensajes);
				}else{

					let funcion = console.log;
					if(this.state.sobreescribir){
					  funcion = this.servicio.actualizarOrdenCompra;
					  datos.id = this.state.id;
					}else{
					  funcion = this.servicio.guardarOrdenCompra;
					}

					funcion(datos).then(res =>  {
							if(res && res.result == 'OK'){

								that.growl.show({severity: 'success', summary: 'Operación completada', detail: 'Información guardada correctamente'});
								setTimeout(function(){
											that.props.history.push('/app/mantenimiento/preventivo/ordenes');
								 }, 500);

							}else if(res && res.result == 'EXISTE'){
								that.growl.show({severity: 'error', summary: 'El Id de ruta '+this.state.id_ruta+' ya se encuentra registrado', detail: 'Ingrese otro código o consulte su información para editarla.'});
							}
					});
				}

	}
	getCommonDataFromChilds(data)
	{
		switch (data.label) {
			case "fecha":
				this.setState({fecha: moment(data.value).format('YYYY-MM-DD').toString(),semana:moment(data.value).week()});
				break;
			case "hora":
				this.setState({hora: data.value});
				break;
		}
	}
	
	getImageInput(title,key)
	{
		return (
		<div className="image-group">
			<label className="title">{title}</label>
			<br />
			<div className="form-group">
			{!this.state[key]?.name && 
				<div className="custom-file-upload">
				<label htmlFor={`file-upload-${key}`} className="p-button-warning p-button m-0 w-100">
					<i className="pi pi-cloud-upload"></i> Seleccionar archivo
				</label>
				<input
					id={`file-upload-${key}`}
					type="file"
					style={{ display: "none" }}
					onChange={(e) =>
					this.setState({ [key]: e.target.files[0] })
					}
					chooseLabel="Seleccionar Archivo"
				/>
				</div>
			}
			{this.state[key]?.name && 
				<div className="file-info d-flex justify-content-between">
				  <div className="label-info">
						{this.state[key].name}
					</div>
			<div className="d-flex">
			{fileState?.[key]?.name && 
					<div className="action-info mr-3">
						{this.state[key].name.includes('.') && 
						<i
						onClick={() => {
							window.open(url+"altadeunidad/"+this.state[key].name,'_blank')
						}}
						className="pi pi-download"
						style={{ fontSize: "1rem", cursor: "pointer" }}
						></i>
						}
					</div>
	        }
				<div className="action-info">
                <i
                  onClick={() => {
                    this.setState({ [key]: null });
					fileState && fileState[key] && (fileState[key].name = null);
				}}
                  className="pi pi-trash"
                  style={{ fontSize: "1.5em", cursor: "pointer" }}
                ></i>
                </div>                                 
				</div>
			</div>
			}
			</div>
		</div>
		)
	}

	preventInvalidInput(event) {
		const invalidKeys = ['e', 'E', '+', '-'];
		if (invalidKeys.includes(event.key)) {
		  event.preventDefault();
		}
	}

	render() {
		
		return (
			<div className="container">
				<Panel header="Editar Order De Compra">
				<Toast ref={(el) => this.growl = el} />
				<form >
				<Row>
					<Col xs="6">
							<div className="form-group">
								<label>Proveedor</label><br/>
									<input type="text"  value={this.state.proveedor} onChange={e => this.setState({proveedor : e.target.value})} className="form-control"/>
							</div>

					</Col>
					<Col xs="6">
							<div className="form-group">
								<label>#Remisión</label>
								<input type="text"  value={this.state.remision} onChange={e => this.setState({remision : e.target.value})} className="form-control"/>
							</div>
					</Col>
				</Row>
				<Row>
					<Col xs="6">
							<div className="form-group">
								<label>#Factura</label><br/>
								<input type="text"  value={this.state.factura} onChange={e => this.setState({factura : e.target.value})}  className="form-control"/>
							</div>
					</Col>
					<Col xs="6">
						<div className="form-group">
							<label>Condiciones y tipo de pago</label><br/>
							<input type="text"  value={this.state.condiciones} onChange={e => this.setState({condiciones : e.target.value})} className="form-control"/>
						</div>
					</Col>
				</Row>
				<Row>
					<Col xs="6">
							<div className="form-group">
								<label>Unidad de medida (Piezas, Lts, Kg, Lote, renta, Servicio)</label><br/>
								<input type="text"  value={this.state.unidad} onChange={e => this.setState({unidad : e.target.value})} className="form-control"/>
							</div>
					</Col>
					<Col xs="6">
							<div className="form-group">
								<label>Fecha</label><br/>
								<PCalendar
									label={"fecha"}
									getCommonDataFromChilds={this.getCommonDataFromChilds}
									showIcon
									value={this.state.fecha}
								/>							
							</div>
					</Col>
				</Row>
				<Row>
				<Col xs="6">
						{this.getImageInput('Carga de cotizaciones previas a la autorizacion', 'cotizaciones')}
					</Col>
					<Col xs="6">
							<div className="form-group">
								<label>Cantidad</label><br/>
								{/* <InputNumber onChange={e=> this.setState({cantidad:e.value})} /> */}
								<input
									type="number"
									inputMode="numeric"
									className="form-control"
									value={this.state.cantidad}
									onChange={(e) => this.setState({ cantidad: e.target.value })}
									onKeyDown={(e) => this.preventInvalidInput(e)}
								/>
							</div>
					</Col>
				</Row>
				<Row>
					<Col xs="6" className="text-right">
							<Button className="p-button-warning" >Autorizar y mover a inventario</Button>&nbsp;
					</Col>
					<Col xs="6" className="text-left">
						 	<Button className="p-button-warning" onClick={this.handleSubmit} >Guardar</Button>
					</Col>
				</Row>
					</form>
				</Panel>
			</div>
		);
	}
}

export default CrearReporteFalla;
