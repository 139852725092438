import React from "react";
import Listado from './ordenes-compra/listado';
import { Link } from "react-router-dom"; 
import { Panel } from 'primereact/panel';
import CheckPermissions from "controllers/permissions/services";
class OrdenesCompraMantenimiento extends React.Component {

	constructor(){
		super();
		this.permissionService = new CheckPermissions()
		this.headerTemplate = this.headerTemplate.bind(this)
	}
	agregarOrden(){
		this.props.history.push({
		 pathname:'/app/mantenimiento/preventivo/ordenes/nueva'
	 	});
	}
	headerTemplate()
	{
		return (<div className="p-panel-header flex-wrap  d-flex">
			<div>
				Listado De Ordenes De Compra
			</div>
			<div>
			{this.permissionService.checkPermission('mantenimiento_preventivo_ordenes_nueva') && <Link to="/app/mantenimiento/preventivo/ordenes/nueva" className="btn btn-primary">
				<i className="pi pi-plus mr-2 v-middle" /> Nueva orden
			</Link>}
			</div>
		</div>);
	}
	render() {
		return (
			<div class="container-fluid">
				<Panel headerTemplate={this.headerTemplate}>
					<Listado history={this.props.history}  />
				</Panel>
			</div>
		);
	}
}

export default OrdenesCompraMantenimiento;
