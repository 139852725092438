import React from "react";
import {
	Row, 
	Col
} from 'reactstrap';
import { Toast } from 'primereact/toast';
import moment from 'moment';
import Select from 'react-select';
import RutaService from 'controllers/ruta/rutaservice';
import ClienteService from 'controllers/cliente/clienteservice';
import PCalendar from '../../ui-components/calendar';
import {Button} from 'primereact/button';

class EditarID extends React.Component {
	constructor(props) {
		super(props);
		this.state = {

		}
		this.servicioR = new RutaService();;
		this.servicio = new ClienteService();
		this.servicio.getClientes().then(data => {
			let arrClientes = [];
			data.forEach(item => {
				arrClientes.push({ value: item.id, label: item.razon_social, rfc: item.rfc, id: item.id });

			});
			this.setState({ clientes: arrClientes });
		});

		this.handleSubmit = this.handleSubmit.bind(this);
		this.getCommonDataFromChilds = this.getCommonDataFromChilds.bind(this);
	}

	componentDidMount()
	{
		this.setState({
			id: this.props.values.id,
			id_ruta: this.props.values.id_ruta,
			cliente_id: this.props.values.cliente_id,
			rfc: this.props.values.rfc,
			broker: this.props.values.broker,
			planta_filial: this.props.values.planta_filial,
			last_update: this.props.values.last_update
		});
	}

	handleSubmit(event) {
		event.preventDefault();
		let that = this;
		if(this.state.last_update){
			this.state.last_update = moment(this.state.last_update).format('YYYY-MM-DD').toString()
		}
		this.servicioR.actualizarRuta(this.state).then(res => {
			if (res && res.result == 'OK') {
				that.growl.show({ severity: 'success', summary: 'Operación completada', detail: 'Información guardada correctamente' });
				setTimeout(function(){
					that.props.history.push('/app/clientes/rutas/consulta');
				}, 500);
			}
		});
	}
	getCommonDataFromChilds(data)
	{
		switch (data.label) {
			case "last_update":
				this.setState({last_update: moment(data.value).format('YYYY-MM-DD').toString()});
				break;
		}
	}
	render() {
		return (
			<div>
				<Toast ref={(el) => this.growl = el} /> 
				<form >
					<Row>
						<Col xs="6">
							<div className="form-group">
								<label>Cliente</label><br />
								<Select options={this.state.clientes} value={this.state.clientes ? this.state.clientes.filter(option => option.value === this.state.cliente_id) : ""} onChange={e => this.setState({ rfc: e.rfc, cliente_id: e.id })} placeholder="Elegir cliente" />
							</div>

						</Col>
						<Col xs="6">
							<div className="form-group">
								<label>R.F.C</label>
								<input type="text" readOnly="true" value={this.state.rfc} className="form-control" />
							</div>
						</Col>
					</Row>
					<Row>
						<Col xs="6">
							<div className="form-group">
								<label>Broker</label><br />
								<input type="text" className="form-control" value={this.state.broker} onChange={e => this.setState({ broker: e.target.value })} />
							</div>
						</Col>
						<Col xs="6">
							<div className="form-group">
								<label>ID de Ruta</label>
								<input type="text" className="form-control" value={this.state.id_ruta} onChange={e => this.setState({ id_ruta: e.target.value })} />
							</div>
						</Col>
					</Row>
					
					<Row>
						<Col xs="6">
							<div className="form-group">
								<label>Planta filial</label><br />
								<input type="text" value={this.state.planta_filial} className="form-control" onChange={e => this.setState({ planta_filial: e.target.value })} />
							</div>
						</Col>
						<Col xs="6">
							<div className="form-group">
								<label>Fecha de última actualización</label><br />
								<PCalendar
									label={"last_update"}
									getCommonDataFromChilds={this.getCommonDataFromChilds}
									showIcon
									value={this.state.last_update}
								/>
							</div>
						</Col>
						<Col xs="12">
							<Button className="p-button-warning" onClick={this.handleSubmit} >Guardar</Button>
						</Col>
					</Row>
				</form>	
			</div>
		);
	}
}

export default EditarID;
