import React from "react";
import {
	Row,Col,CardTitle
} from 'reactstrap';

import { Toast } from 'primereact/toast';
import moment from 'moment';
import Select from 'react-select';
import UnidadService  from 'controllers/unidad/unidadService';
import OperadorServices  from 'controllers/operador/operador-service';
import PCalendar from '../../../ui-components/calendar';
import MantenimientoService from  'controllers/mantenimiento/mantenimiento-service';
import { Panel } from 'primereact/panel';
import {Button} from 'primereact/button';

const options = [];

class CrearReporteFalla extends React.Component {
	constructor(props){
		super(props);
		this.state = {
			fecha: ""
		};
	 	this.servicio = new MantenimientoService();
		this.servicioU = new UnidadService();
		this.servicioO = new OperadorServices();
		this.getCommonDataFromChilds = this.getCommonDataFromChilds.bind(this);

		this.servicioU.getUnidades().then(data =>  {
			let arrUnidades = [];
			data.forEach(item=>{
				arrUnidades.push({value:item.id,label:item.placas});

			});
			this.setState({unidades: arrUnidades});
		});
		this.servicioO.getOperadores().then(data =>  {
			let arrOperadores = [];
			data.forEach(item=>{
				arrOperadores.push({value:item.usuario_id,label:item.nombre});

			});
			this.setState({operadores: arrOperadores});
		});
		this.handleSubmit = this.handleSubmit.bind(this);
		
	}

	handleSubmit(event) {
				event.preventDefault();

				let that = this;

				let datos ={};
				const data = new FormData();
				datos.unidad = this.state.unidad;
				datos.operador = this.state.operador;
				datos.descripcion = this.state.descripcion;
				datos.fecha = this.state.fecha


				data.append("reporte",JSON.stringify(datos));



				// data.append("contrato",this.state.contrato); archivos

				let mensajes = [];

				if(!this.state.unidad){
						mensajes.push({severity: 'error', summary: 'Información incompleta', detail: 'Seleccione unidad'});
				}
				if(!this.state.operador){
						mensajes.push({severity: 'error', summary: 'Información incompleta', detail: 'Seleccione un operador'});
				}
				if(!this.state.descripcion){
						mensajes.push({severity: 'error', summary: 'Información incompleta', detail: 'Ingrese una descripción'});
				}

				if(this.state.evidencia){
						data.append("evidencia",this.state.evidencia);
						//mensajes.push({severity: 'error', summary: 'Información incompleta', detail: 'Seleccione un archivo de evidencia'});
				}

				if(mensajes.length){
					this.growl.show(mensajes);
				}else{
					this.servicio.guardarReporte(datos).then(res =>  {
							if(res && res.result == 'OK'){

								that.growl.show({severity: 'success', summary: 'Operación completada', detail: 'Información guardada correctamente'});
								setTimeout(function(){
										 	that.props.history.push('/app/mantenimiento/preventivo/fallas');
								 }, 500);

							}else if(res && res.result == 'EXISTE'){
								that.growl.show({severity: 'error', summary: 'El Id de ruta '+this.state.id_ruta+' ya se encuentra registrado', detail: 'Ingrese otro código o consulte su información para editarla.'});
							}
					});
				}
	}
	getCommonDataFromChilds(data)
	{
		switch (data.label) {
			case "fecha":
				this.setState({fecha: moment(data.value).format('YYYY-MM-DD').toString(),semana:moment(data.value).week()});
				break;
			case "hora":
				this.setState({hora: data.value});
				break;
		}
	}

	getImageInput(title,key)
  {
    return (
      <div className="image-group">
        <label className="title">{title}</label>
        <br />
        <div className="form-group">
          {!this.state[key]?.name && 
            <div className="custom-file-upload">
              <label htmlFor={`file-upload-${key}`} className="p-button-warning p-button m-0 w-100">
                <i className="pi pi-cloud-upload"></i> Seleccionar archivo
              </label>
              <input
                id={`file-upload-${key}`}
                type="file"
                style={{ display: "none" }}
                onChange={(e) =>
                  this.setState({ [key]: e.target.files[0] })
                }
                chooseLabel="Seleccionar Archivo"
              />
            </div>
          }
          {this.state[key]?.name && 
            <div className="file-info d-flex justify-content-between">
              <div className="label-info">
                {this.state[key].name}
              </div>
              <div className="action-info">
                <i
                  onClick={() => {
                    this.setState({ [key]: null });
                  }}
                  className="pi pi-trash"
                  style={{ fontSize: "1.5em", cursor: "pointer" }}
                ></i>
                </div>                            
            </div>
          }
        </div>
      </div>
    )
  }

	render() {
		
		return (
			<div className="container">
				<Panel header="Alte De Reporte Falla">
					<Toast ref={(el) => this.growl = el} />
					<form >
						<Row>
							<Col md="4">
								<div className="form-group">
									<label>Unidad</label><br/>
									<Select options={this.state.unidades}  onChange={e =>	this.setState({unidad: e.value}) }  placeholder="Elegir unidad"/>
								</div>
							</Col>
							<Col md="4">
							<div className="form-group">
								<label>Operador</label>
								<Select options={this.state.operadores}  onChange={e =>	this.setState({operador: e.label}) } placeholder="Elegir operador"/>
							</div>
							</Col>
							<Col md="4">
							<div className="form-group">
								<label>Fecha</label><br/>
								<PCalendar
									label={"fecha"}
									getCommonDataFromChilds={this.getCommonDataFromChilds}
									showIcon
									value={this.state.fecha}
								/>
							</div>
							</Col>
						</Row>
						<Row>
							<Col xs="12">
									<div className="form-group">
										<label>Descripción de la falla</label><br/>
										<input type="text"  value={this.state.descripcion} onChange={(e) => this.setState({descripcion:e.target.value})}  className="form-control"/>
									</div>
							</Col>
						</Row>
						<Row>
							<Col md="6">
								{this.getImageInput("Evidencia de la falla", "evidencia")}
							</Col>
						</Row>
							<center> <Button className="p-button-warning" onClick={this.handleSubmit} >Guardar</Button></center>
						</form>
				</Panel>
			</div>
		);
	}
}

export default CrearReporteFalla;
