import React, { Component } from 'react';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import RutaService  from 'controllers/ruta/rutaservice';
import {Button} from 'primereact/button';
import EditarID from './editar';
import {Dialog} from 'primereact/dialog';
import { Toast } from 'primereact/toast';
import CheckPermissions from 'controllers/permissions/services';

export default class RutasListado extends Component {

    constructor(props) {
        super(props);
        this.state = {
            globalFilter: null
        };
        this.servicio_rutas = new RutaService();
        this.permissionService = new CheckPermissions();
    }

    eliminar(column, rowData){
      this.setState({visible2:true,selectedItemIndex:column.rowIndex,selectedItem:rowData});

    }

    editar(rowData){
      this.props.history.push({
         pathname:'/app/ruta/editar',
         state: rowData
       });
    }

    confirmar_borrado(){

      this.setState({visible2:false});
      let ex =this.props.rutas.filter((val,i) => i !== this.state.selectedItemIndex)
      ex.map(data=> {
        if(data.direccion_origen){
        data.direccion_origen = this.props.addre(data.direccion_origen)
      }
      if(data.direccion_final){
        data.direccion_final = this.props.addre(data.direccion_final)
      }
      return data
    })
      
      this.setState({
        rutas: ex
      });
      let that = this;
      this.servicio_rutas.eliminarRuta(this.state.selectedItem.id).then(res =>  {
          if(res && res.result == 'OK'){
            that.growl.show({severity: 'success', summary: 'Operación completada', detail: 'Registro borrado'});
            this.props.handleRutasTable();
          }
      });
    }


    actionTemplate(rowData, column) {
      return (<div>
        {this.permissionService.checkPermission('ruta_editar') && <Button
          type="button" 
          tooltip="Editar" 
          tooltipOptions={{ position: 'top' }} 
          icon="pi pi-pencil" 
          onClick={e=> this.editar(rowData)}
          className="p-button-warning"/>}
        {this.permissionService.checkPermission('ruta_borrar') && <Button
          type="button" 
          tooltip="Borrar"
          tooltipOptions={{ position: 'top' }} 
          icon="pi pi-trash" 
          onClick={e=> this.eliminar(column, rowData)}
          className="p-button-warning"
        />}
      </div>);
    }
    render() {
        const footer = (
            <div>
                <Button label="Sí" className='p-button-warning mr-2' icon="pi pi-check" onClick={this.confirmar_borrado.bind(this)} />
                <Button label="No" className='p-button-warning' icon="pi pi-times" onClick={e=> this.setState({visible2:false})}/>
            </div>
        );

        return (<div>
            <Toast ref={(el) => this.growl = el} />
            <Dialog header="Editar"  visible={this.state.visible} style={{width: '50vw',overflow:'scroll'}} modal={true} onHide={() => this.setState({visible: false,visible2:false	})}>
              <EditarID values={this.state.item} growl={this.growl} onSaved={()=> this.setState({visible:false})}/>
            </Dialog>
            <Dialog header="Eliminar"  footer={footer} visible={this.state.visible2} style={{width: '50vw'}} modal={true} onHide={() => this.setState({visible: false,visible2:false	})}>
              ¿Confirma eliminar?
            </Dialog>
            <DataTable value={this.props.rutas} paginator={true}
              paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
              currentPageReportTemplate="Showing {first} to {last} of {totalRecords}" rows={10} >
                <Column field="broker" header="Broker" />
                <Column field="facturar_a" header="Cliente" />
                <Column field="id_ruta" header="ID Ruta" />
                <Column field="version" header="Versión" />
                <Column field="leg" header="Leg" />
                <Column field="direccion_origen" header="Planta origen" />
                <Column field="direccion_final" header="Planta destino" />
                <Column  body={this.actionTemplate.bind(this)} header="Opciones" style={{ textAlign: 'center',width: '10%'}} />
            </DataTable></div>
        );
    }
}
