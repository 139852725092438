import React from "react";
import { Row, Col, InputGroupText, InputGroup, Input } from "reactstrap";
import { Toast } from "primereact/toast";
import Select from "react-select";
import { Panel } from 'primereact/panel';
import OperadorServices from "controllers/operador/operador-service";
import RutaService from "controllers/ruta/rutaservice";
import ClienteService from "controllers/cliente/clienteservice";
import CustomAddress from "../CustomAddress";

const stops_data = [
  {
    key: "chk_s1",
    chk_s1: true,
  },
  {
    key: "chk_s2",
    chk_s2: true,
  },
  {
    key: "chk_s3",
    chk_s3: true,
  },
  {
    key: "chk_s4",
    chk_s4: true,
  },
  {
    key: "chk_s5",
    chk_s5: true,
  },
  {
    key: "chk_s6",
    chk_s6: true,
  },
  {
    key: "chk_s7",
    chk_s7: true,
  },
  {
    key: "chk_s8",
    chk_s8: true,
  },
  {
    key: "chk_s9",
    chk_s9: true,
  },
  {
    key: "chk_s10",
    chk_s10: true,
  },
];

const tipo_unidades = [
  { value: "Rabón", label: "Rabón" },
  { value: "3.5 Toneladas", label: "3.5 Toneladas" },
  { value: "48 pies", label: "48 pies" },
  { value: "53 pies", label: "53 pies" },
  { value: "Torton", label: "Torton" },
  { value: "Vehículo", label: "Vehículo" },
];

const categorias = [
  { label: "OW", value: "OW" },
  { label: "RT", value: "RT" },
];
class EditarVersion extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      rutas: [],
      chk_s1: true,
      chk_s2: true,
      chk_s3: true,
      chk_s4: true,
      chk_s5: true,
      chk_s6: true,
      chk_s7: true,
      chk_s8: true,
      chk_s9: true,
      chk_s10: true,
      ruta_id: null,
      tarifa_servicio: 0,
      tarifa_stop: 0,
      comision_stop: 0,
      comision_servicio: 0,
      total_comision_servicio: 0,
      submit_disabled:false
    };
    this.servicio = new OperadorServices();
    this.servicioR = new RutaService();
    this.servicio = new ClienteService();

    this.servicio.getClientes().then((data) => {
      let arrClientes = [];
      data.forEach((item) => {
        if (item.estatus !== 0) {
          arrClientes.push({
            value: item.id,
            label: item.razon_social,
            rfc: item.rfc,
          });
        }
      });
      this.setState({ clientes: arrClientes });
    });

    this.handleSubmit = this.handleSubmit.bind(this);
    this.seleccionar_cliente = this.seleccionar_cliente.bind(this);
    this.conteoDeStops = this.conteoDeStops.bind(this);
  }

  componentDidMount()
	{
    if(this.props?.values){
      this.servicioR.getRutaVersion({ruta_id: this.props.values.id, version: this.props.values.version}).then((res) => {
        if(res.length){
            const stops = stops_data.map((val, i) => {
              if(res[0]?.["stop"+[i+1]]){
                this.setState({ [val.key]: !this.state[val.key] });
              } 
              return res[0]?.["stop" + [i+1]]}
            );
          this.setState(({
            id: res[0].id,
            ruta_id: res[0].ruta_id,
            cliente_id: res[0].cliente_id,
            rfc: res[0].rfc,
            broker: res[0].broker,
            planta_filial: res[0].planta_filial,
            last_update: res[0].last_update,
            version: res[0].version,
            leg: res[0].leg,
            categoria: res[0].categoria,
            tipo_unidad: res[0].tipo_unidad,
            tarifa_servicio: res[0].tarifa_servicio,
            comision_servicio:res[0].comision_servicio, 
            total_comision_servicio: res[0].total_comision_servicio, 
            direccion_origen: res[0].direccion_origen,
            direccion_final: res[0].direccion_final,
            stops : stops
          }))
          this.updateRutaId(res[0].cliente_id)
        }
      });
    }
	}
  
  handleSubmit(event) {
    event.preventDefault();
    let that = this;

    let mensajes = [];
    if (!this.state.cliente_id) {
      mensajes.push({
        severity: "error",
        summary: "Información incompleta",
        detail: "Seleccione un cliente",
      });
    }
    if (!this.state.ruta_id) {
      mensajes.push({
        severity: "error",
        summary: "Información incompleta",
        detail: "Seleccione una ruta",
      });
    }
    if (!this.state.version) {
      mensajes.push({
        severity: "error",
        summary: "Información incompleta",
        detail: "Ingrese una versión",
      });
    }
    if (!this.state.leg) {
      mensajes.push({
        severity: "error",
        summary: "Información incompleta",
        detail: "Ingrese un Leg",
      });
    }
    if (!this.state.categoria) {
      mensajes.push({
        severity: "error",
        summary: "Información incompleta",
        detail: "Seleccione una categoría",
      });
    }
    if (!this.state.tipo_unidad) {
      mensajes.push({
        severity: "error",
        summary: "Información incompleta",
        detail: "Seleccione el tipo de unidad",
      });
    }
    if (!this.state.tipo_unidad) {
      mensajes.push({
        severity: "error",
        summary: "Información incompleta",
        detail: "Seleccione el tipo de unidad",
      });
    }
    // if (!this.state.caja_drop) {
    // 	mensajes.push({ severity: 'error', summary: 'Información incompleta', detail: 'Seleccione el uso de caja drop' });
    // }
    // if (!this.state.frecuencia_semanal) {
    // 	mensajes.push({ severity: 'error', summary: 'Información incompleta', detail: 'Seleccione la frecuencia semanal' });
    // }
    if (!this.state.direccion_origen) {
      mensajes.push({
        severity: "error",
        summary: "Información incompleta",
        detail: "Ingrese la Planta de origen",
      });
    }
    if (!this.state.direccion_final) {
      mensajes.push({
        severity: "error",
        summary: "Información incompleta",
        detail: "Ingrese la Planta final",
      });
    }
    if (!this.state.tarifa_servicio) {
      mensajes.push({
        severity: "error",
        summary: "Información incompleta",
        detail: "Ingrese la tárifa de servicio",
      });
    }

    let suData = this.state;
    delete suData.submit_disabled;
    suData["tarifa_total"] =
      parseInt(this.state.tarifa_servicio) + this.conteoDeStops().sum;
    if (mensajes.length) {
      this.growl.show(mensajes);
    } else {
      this.setState({ submit_disabled: true });
      this.servicioR.updateRutaVersion(suData).then((res) => {
        this.setState({ submit_disabled: false });
        if (res && res.result == "OK") {
          that.growl.show({
            severity: "success",
            summary: "Operación completada",
            detail: "Información guardada correctamente",
          });
          setTimeout(function () {
            that.props.history.push("/app/clientes/rutas/consulta");
          }, 500);
        }
      });
    }
  }

  seleccionar_cliente(event) {
    this.setState({ rutas: [], ruta_id: "" });
    this.setState({ cliente_id: event.value });
    this.updateRutaId(event.value)
  }

  updateRutaId(cliente_id){
    this.servicioR.getRutas(cliente_id).then((data) => {
      let arrRutas = [];
      data.forEach((item) => {
        arrRutas.push({ value: item.id, label: item.id_ruta });
      });
      this.setState({ rutas: arrRutas });
    });
  }

  conteoDeStops() {
    let sum = 0,
      comision = 0;
    for (let i = 1; i <= 10; i++) {
      if (this.state[`stop${i}`]) {
        let record = JSON.parse(this.state[`stop${i}`]);
        if (
          record["value_costo_del_stop"] &&
          Number(record["value_costo_del_stop"])
        ) {
          sum += Number(record["value_costo_del_stop"]);
        }
        if (
          record["comision_a_operador"] &&
          Number(record["comision_a_operador"])
        ) {
          comision += Number(record["comision_a_operador"]);
        }
      }
    }
    return { sum, comision };
  }

  inputChangeHandler(data, index) {
    console.log('check --->', this.state[data.key],data);
    if(!this.state[data.key]){
      this.setState({ [data.key]: !this.state[data.key], ["stop"+[index+1]]: null });
    }
    this.setState({ [data.key]: !this.state[data.key]});
  }

  ReceiveAddress = (data) => {
    if (data.step === 100) {
      this.setState({ [`direccion_origen`]: JSON.stringify(data) });
    } else if (data.step === 101) {
      this.setState({ [`direccion_final`]: JSON.stringify(data) });
    } else if (data.step) {
      this.setState({ [`stop${data.step}`]: JSON.stringify(data) });
      this.conteoDeStops();
    }
  };

  commisionCalcu = (val) => {
    let rota_commision = 0
    this.setState({ comision_servicio: (val? val: this.state.tarifa_servicio/10)+ rota_commision })
  }

  render() {
    const es = {
      firstDayOfWeek: 1,
      dayNames: [
        "domingo",
        "lunes",
        "martes",
        "miércoles",
        "jueves",
        "viernes",
        "sábado",
      ],
      dayNamesShort: ["dom", "lun", "mar", "mié", "jue", "vie", "sáb"],
      dayNamesMin: ["D", "L", "M", "X", "J", "V", "S"],
      monthNames: [
        "Enero",
        "Febrero",
        "Marzo",
        "Abril",
        "Mayo",
        "Junio",
        "Julio",
        "Agosto",
        "Septiembre",
        "Octubre",
        "Noviembre",
        "Diciembre",
      ],
      monthNamesShort: [
        "ene",
        "feb",
        "mar",
        "abr",
        "may",
        "jun",
        "jul",
        "ago",
        "sep",
        "oct",
        "nov",
        "dic",
      ],
    };
    return (
      <div className="container">
        <Panel header="Agregar Versión De Ruta">
          <Toast ref={(el) => (this.growl = el)} />
            <form onSubmit={this.handleSubmit}>
              <Row>
                <Col xs="12" md="6">
                  <div className="form-group">
                    <label>Cliente</label>
                    <Select
                      options={this.state.clientes}
                      value={this.state.clientes ? this.state.clientes.filter(option => option.value == this.state.cliente_id): ""}
                      onChange={this.seleccionar_cliente}
                      placeholder="Elegir Cliente"
                    />
                  </div>
                </Col>
                <Col xs="12" md="6">
                  <div className="form-group">
                    <label>ID de Ruta</label>
                    <br />
                    <Select
                      options={this.state.rutas}
                      value={this.state.rutas ? this.state.rutas.filter(option => option.value == this.state.ruta_id): ""}
                      onChange={(e) => this.setState({ ruta_id: e.value })}
                      placeholder="Elegir Ruta"
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col xs="12" md="6">
                  <div className="form-group">
                    <label>Versión</label>
                    <input
                      type="text"
                      value={this.state.version}
                      onChange={(e) => this.setState({ version: e.target.value })}
                      className="form-control"
                    />
                  </div>
                </Col>
                <Col xs="12" md="6">
                  <div className="form-group">
                    <label>Leg</label>
                    <input
                      type="text"
                      value={this.state.leg}
                      onChange={(e) => this.setState({ leg: e.target.value })}
                      className="form-control"
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col xs="12" md="6">
                  <div className="form-group">
                    <label>Categoría</label>
                    <Select
                      options={categorias}
                      value={this.state.categoria ? categorias.filter(option => option.value == this.state.categoria): ""}
                      onChange={(e) => this.setState({ categoria: e.value })}
                      placeholder="Elegir Categoría..."
                    />
                  </div>
                </Col>
                <Col xs="12" md="6">
                  <div className="form-group">
                    <label>Tipo de Unidad</label>
                    <Select
                      options={tipo_unidades}
                      value={this.state.tipo_unidad ? tipo_unidades.filter(option => option.value == this.state.tipo_unidad): ""}
                      onChange={(e) => this.setState({ tipo_unidad: e.value })}
                      placeholder="Elegir tipo de unidad..."
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col xs="12">
                  <div className="form-group">
                    <label>Planta de origen</label>
                    <CustomAddress
                      direccion_origen = {this.state.direccion_origen}
                      is_nombre={"true"}
                      step={100}
                      senderAdressToParent={this.ReceiveAddress}
                      isLocalidadRequired={"false"}
                      isComisionAOperador={"false"}
                    />
                  </div>
                </Col>
              </Row>
              {stops_data.map((data, i) => {
                return (
                  <Row key={`multiple_${i}`}>
                    <Col xs="12">
                      <div className="form-group">
                        <label>
                          <input
                            type="checkbox"
                            checked={!this.state[data.key]}
                            onChange={(e) => {
                              this.inputChangeHandler(data, i);
                            }}
                          />{" "}
                          Agregar Stop {i + 1}
                        </label>
                        <div className={this.state[data.key] ? "d-none" : ""}>
                          <CustomAddress
                            stop = {this.state.stops?.[i]}
                            requiredField={"false"}
                            is_costo_del_stop={"false"}
                            is_nombre={"true"}
                            step={i + 1}
                            senderAdressToParent={this.ReceiveAddress}
                            isLocalidadRequired={"false"}
                            isComisionAOperador={"true"}
                          />
                        </div>
                      </div>
                    </Col>
                  </Row>
                );
              })}

              <Row>
                <Col xs="12">
                  <div className="form-group">
                    <label>Planta final </label>
                    <CustomAddress
                      direccion_final = {this.state.direccion_final}
                      is_nombre={"true"}
                      step={101}
                      senderAdressToParent={this.ReceiveAddress}
                      isLocalidadRequired={"false"}
                      isComisionAOperador={"false"}
                    />
                  </div>
                </Col>
              </Row>

              <Row>
                <Col xs="12" md="6">
                  <div className="form-group">
                    <label for="tarifa_servicio">Tarifa por servicio</label>
                    <br />
                      <InputGroup>
                      <InputGroupText>
                        $
                      </InputGroupText>
                      <Input placeholder="0.00" id="tarifa_servicio" type="number" value={this.state?.tarifa_servicio} onChange={(e) =>
                        { 
                            this.setState({ tarifa_servicio: e.target.value });
                            this.commisionCalcu(e.target.value)
                        }
                      }/>
                      </InputGroup>
                  </div>
                </Col>
                <Col xs="12" md="6">
                  <div className="form-group">
                    <label for="comision_servicio">Comisión a operador por servicio</label>
                    <br />
                    <InputGroup>
                      <InputGroupText>
                        $
                      </InputGroupText>
                      <Input placeholder="0.00"
                      id ="comision_servicio"
                      type="number"   
                      value={
                        this.state.comision_servicio ?  this.state.comision_servicio :
                        this.state.tarifa_servicio / 10
                      }
                      onChange={(e) =>
                        this.setState({ comision_servicio: e.target.value })
                      }
                      />
                      </InputGroup>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col xs="12" md="6">
                  <div className="form-group">
                    <label for="total_comision_servicio">Comisión total de Stops</label>
                    <br />      
                    <InputGroup>
                      <InputGroupText>
                        $
                      </InputGroupText>
                      <Input
                        placeholder="0.00"
                        id="total_comision_servicio"
                        type="number"
                        value={
                            this.state.total_comision_servicio? this.state.total_comision_servicio: this.conteoDeStops().comision 
                        }
                        onChange={(e) =>
                          this.setState({ total_comision_servicio: e.target.value })
                        }
                        />
                      </InputGroup>
                  </div> 
                </Col>
              </Row>

              <center>
                {" "}
                <button disabled={this.state.submit_disabled} className="btn btn-primary">Guardar</button>
                {(this.state.submit_disabled)?<p>Cargando...</p>:<></>}
              </center>
            </form>
        </Panel>
      </div>
      
    );
  }
}

export default EditarVersion;
