import React from "react";
import VigilanciaListado from './lista-corta';

import { TabView,TabPanel } from 'primereact/tabview';
import { Panel } from 'primereact/panel';

class ReportesVigilancia extends React.Component {
	constructor(){
		super();
		this.state = {
			activeIndex : 0
		}
	}

	render() {
		return (
			<div class="container-fluid">
				<Panel header="Reportes de vigilancia">
	<TabView activeIndex={this.state.activeIndex} onTabChange={(e) => this.setState({activeIndex: e.index})}>
						<TabPanel header="Inspección 18 puntos">
								<VigilanciaListado tipo="1"/>
						</TabPanel>
							<TabPanel header="Inspección 7 puntos">
								<VigilanciaListado tipo="2"/>
						</TabPanel>
							<TabPanel header="Checklist despacho">
								<VigilanciaListado tipo="3"/>
						</TabPanel>
							
					</TabView>
				</Panel>
			</div>
		);
	}
}

export default ReportesVigilancia;
