import React, { Component } from 'react';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {InputText} from 'primereact/inputtext';
import {Dropdown} from 'primereact/dropdown';
import {MultiSelect} from 'primereact/multiselect';
import OperadorServices  from 'controllers/operador/operador-service';
import {Button} from 'primereact/button';
import EditarOperador from './editar';
import {Dialog} from 'primereact/dialog';
import { Toast } from 'primereact/toast';

export default class OperadorListado extends Component {

    constructor() {
        super();
        this.state = {
            globalFilter: null,
            visible:false,
            visible2:false
        };
        this.servicioOperador = new OperadorServices();
        this.eliminar = this.eliminar.bind(this);
        this.editar = this.editar.bind(this);
        this.view_documents = this.view_documents.bind(this);
    }

    componentDidMount() {
      if(this.props.parametros){
        this.servicioOperador.getFiltroOperadores(this.props.parametros).then(data => this.setState({cars: data}));
      }else{
        this.servicioOperador.getOperadores().then(data => this.setState({cars: data}));
      }
    }

    eliminar(column, rowData){
      this.setState({visible2:true,selectedItemIndex:column.rowIndex,selectedItem:rowData});

    }

    editar(rowData){
    //  this.setState({visible:true,item:column.rowData});
      this.props.history.push({
         pathname:'/app/operador/editar',
         state: rowData
       });
    }

    view_documents(column){
      this.props.history.push({
         pathname:'/app/operador/documents',
         state: column.rowData
       });
    }

    confirmar_borrado(){

      this.setState({visible2:false});
      this.setState({
        cars: this.state.cars.filter((val,i) => i !== this.state.selectedItemIndex)
      });
      let that = this;
      this.servicioOperador.eliminarOperador(this.state.selectedItem.usuario_id).then(res =>  {
          if(res && res.result == 'OK'){
            that.growl.show({severity: 'success', summary: 'Operación completada', detail: 'Registro borrado'});
          }
      });

    }


    actionTemplate(rowData, column) {
      return (<div>
        <Button
          type="button" title='Editar' icon="pi pi-pencil" onClick={e=> this.editar(rowData)}
          className=""/><Button
          type="button" title='Eliminar' icon="pi pi-trash" onClick={e=> this.eliminar(column, rowData)}
           className="p-button-warning"
        />
      </div>);
    }
    documentTemplate(rowData, column) {
      return (<div>
        <Button
          type="button" title='Ver documentos' icon="pi pi-pencil" onClick={e=> this.view_documents(column)}
          className=""/>
      </div>);
    }

    render() {
        const footer = (
          <div>
                <Button label="Sí" className='p-button-warning mr-2' icon="pi pi-check" onClick={this.confirmar_borrado.bind(this)} />
                <Button label="No" className='p-button-warning' icon="pi pi-times" onClick={e=> this.setState({visible2:false})}/>
            </div>

        );
        let header = (
            <div style={{'textAlign':'left'}}>
                <i className="pi pi-search" style={{margin:'4px 4px 0 0'}}></i>
                <InputText type="search" onInput={(e) => this.setState({globalFilter: e.target.value})} placeholder="Global Search" size="50"/>
            </div>
        );

        return (<div>
            <Toast ref={(el) => this.growl = el} />
            <Dialog header="Editar"  visible={this.state.visible} style={{width: '50vw',height:'90vh',overflow:'scroll'}} modal={true} onHide={() => this.setState({visible: false,visible2:false	})}>
              <EditarOperador values={this.state.item}  growl={this.growl} onSaved={()=> this.setState({visible:false})}/>
            </Dialog>
            <Dialog header="Eliminar"  footer={footer} visible={this.state.visible2} style={{width: '50vw'}} modal={true} onHide={() => this.setState({visible: false,visible2:false	})}>
              ¿Confirma eliminar?
            </Dialog>
            <DataTable value={this.state.cars} paginator={true} rows={10} header={header} globalFilter={this.state.globalFilter}>
                <Column field="numero_empleado" header="Número de empleado" />
                <Column field="fecha_ingreso" header="Fecha de ingreso" />
                <Column field="nombref" header="Nombre" />
                <Column field="apellido_paterno" header="Apellido paterno" />
                <Column field="apellido_materno" header="Apellido materno" />

                <Column field="fecha_nacimiento" header="Fecha de nacimiento"  />
                <Column field="estado_civil" header="Estado Civil"  />
                <Column body={this.documentTemplate.bind(this)} header="Documentos" style={{ textAlign: 'center'}} />
                <Column body={this.actionTemplate.bind(this)} header="Opciones" style={{ textAlign: 'center'}} />
            </DataTable>

      </div>);
    }
}
