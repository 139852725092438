import React from "react";
import {Panel} from 'primereact/panel';
import {Button} from 'primereact/button';
import EditarCliente from './editar';
import EditarVersion from "./editor-version";

class EditarPagina extends React.Component {
	constructor(props){
		super(props);
		this.state={};
		this.headerTemplate = this.headerTemplate.bind(this);
	}


	headerTemplate()
	{
		return (<div className="p-panel-header flex-wrap  d-flex">
			<div>
				Editar
			</div>
			<div>
			<Button style={{float:'right'}} className="p-button-warning" onClick={e=> this.props.history.goBack()}  label="Regresar"/>
			</div>
		</div>);
	}

	render() {
		return (
			<div className="container">
				<Panel headerTemplate={this.headerTemplate}>
					{this.props?.location?.state?.version ? <EditarVersion {...this.props}  values={this.props.location.state} /> :<EditarCliente {...this.props}  values={this.props.location.state} />}
				</Panel>
			</div>
		);
	}
}

export default EditarPagina;
