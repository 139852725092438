import React from "react";
import {
	Card,
	CardBody,Container, Row,Col,CardTitle

} from 'reactstrap';

import PCalendar from '../../ui-components/calendar';
import PTimer from '../../ui-components/timer';
import moment from 'moment';
import Select from 'react-select';
import RemolqueServices from 'controllers/remolque/remolqueservice';

import UnidadService  from 'controllers/unidad/unidadService';

import { Toast } from 'primereact/toast';
import {Button} from 'primereact/button';
import { InputSwitch } from 'primereact/inputswitch';
import {Fieldset} from 'primereact/fieldset';
import VigilanciaService from  'controllers/vigilancia/vigilancia-service';
import { Panel } from 'primereact/panel';
class InspeccionVigilancia extends React.Component {
	constructor(){
		super();
		this.state= {checks:[]};
		this.servicio = new VigilanciaService();
		this.servicioU = new UnidadService();
		this.servicioR = new RemolqueServices();

		this.servicioU.getUnidades().then(data =>  {
			let arrUnidades = [];
			data.forEach(item=>{
				arrUnidades.push({value:item.id,label:item.eco});

			});
			this.setState({unidades: arrUnidades});
		});

		this.handleSubmit = this.handleSubmit.bind(this);
		this.getCommonDataFromChilds = this.getCommonDataFromChilds.bind(this);
		
		this.servicioU.getUnidades().then(data =>  {
			let arrUnidades = [];
			data.forEach(item=>{
				arrUnidades.push({value:item.id,label:item.placas});

			});
			this.setState({unidades: arrUnidades});
		});

		this.servicioR.getRemolques().then(data =>  {
			let arrRemolques= [];
			data.forEach(item=>{
				arrRemolques.push({value:item.id,label:item.eco});

			});
			this.setState({remolques: arrRemolques});
		});
	}
	uploadFileReport(event)
	{
		var re = /(\.jpg|\.jpeg|\.png)$/i;
		let that = this;
		if(re.exec(event.target.files[0].name)) {
			const data = new FormData();
			data.append("folio",this.state.folio);
			data.append("file",event.target.files[0]);
			this.servicio.uploadReportFile(data).then((res) => {
				if (res && res.result == "OK") {
					this.setState({foto_id:res.data.file_name})
				}
			})
		}
		else
		{
			that.growl.show({severity: 'error', summary: 'Subida fallida', detail: 'El formato del archivo debe ser .jpg | .jpeg | .png'});
		}
	}
	handleSubmit(event) {
				event.preventDefault();
				let datos ={};
				let that = this;


				datos.folio = this.state.folio;
				datos.fecha = moment( this.state.fecha).format('YYYY-MM-DD').toString();
				datos.num_unidad = this.state.num_unidad;
				datos.num_remolque = this.state.num_remolque;
				datos.hora = (this.state.hora)?this.state.hora:moment(this.state.fecha).format('hh:mm A').toString();
				datos.checks = this.state.checks;
				datos.foto_id = this.state.foto_id || "";
				this.servicio.guardarReporteDespacho(datos).then(res =>  {
						if(res && res.result == 'OK'){

							that.growl.show({severity: 'success', summary: 'Operación completada', detail: 'Información guardada correctamente'});
							setTimeout(function(){
										that.props.history.push('/app/inspeccion/reportes');
							 }, 500);

						}else if(res && res.result == 'EXISTE'){
							that.growl.show({severity: 'error', summary: 'El Id de ruta '+this.state.id_ruta+' ya se encuentra registrado', detail: 'Ingrese otro código o consulte su información para editarla.'});
						}
				});

	}
	vista(elemento,i){
				let arreglo = Object.assign({}, this.state.checks);
				arreglo[i] = false
				const  objeto = (value) =>{
					arreglo[i] = value;
					return arreglo;
				};
				return (
						<Col xs="4">
							<div Style="margin:5px;">
								<InputSwitch Style="margin-bottom: -7px" checked={this.state.checks[i]}  onChange={(e) => this.setState({ checks:objeto(e.value)})} />
								&nbsp;
								{elemento}
							</div>
						</Col>

				);
				/*	<Col xs="6">
							<div className="form-group">
								<label>Comentario</label><br/>
									<input type="text"  value={this.state.folio} onChange={e => this.setState({folio : e.target.value})} className="form-control"/>
							</div>

					</Col>*/
	}

	listadoDocumentosOperador(){
		const elementos = [
			'Licencia Federal',
			'Copia de Examen Medico (apto)',
			'Carta de Porte',
			'Documentos de la Mercancía'
		];
		const listItems = elementos.map((elemento,i) =>
			this.vista(elemento,i)
	 );
	 return (
		 <Row>
			{listItems}
		 </Row>
	 )
	}


	listadoDocumentosUnidad(n = 0){
		const elementos = [
			'Póliza de Seguro',
			'Tarjeta de Circulación',
			'Placas y Engomado',
			'Verificación Físico-mecánica (Boleta y Engomado)',
			'Verificación Ecológica (Boleta y Engomado)',
			'Calcomanía PASE (para peajes)'
		];
		const listItems = elementos.map((elemento,i) =>
			this.vista(elemento,i+10 + 10*n)
		);
		return (
			<Row>
			{listItems}
			</Row>
		)
	}

	listadoSeguridad(){
		const elementos = [
			'Chaleco',
			'Zapatos de Seguridad',
			'Casco',
			'Lentes de Seguridad',
			'Tapones Auditivos',
			'Cubre bocas',
			'Careta',
			'Gel antibacterial'
		];
		const listItems = elementos.map((elemento,i) =>
			this.vista(elemento,i+30)
		);
		return (
			<Row>
			{listItems}
			</Row>
		)
	}
	listadoSujecion(){
		const elementos = [
			'Bandas',
			'Gatas o barras logísticas'
		];

		const listItems = elementos.map((elemento,i) =>
			this.vista(elemento,i+40)
		);
		return (
			<Row>
			{listItems}
			</Row>
		)
	}
	getCommonDataFromChilds(data)
	{
		switch (data.label) {
			case "fecha":
				this.setState({fecha: moment(data.value).format('YYYY-MM-DD').toString(),semana:moment(data.value).week()});
				break;
			case "hora":
				this.setState({hora: data.value});
				break;
		}
	}
  getImageInput(title,key)
  {
    return (
      <div className="image-group">
        <label className="title">{title}</label>
        <br />
        <div className="form-group">
          {!this.state[key]?.name && 
            <div className="custom-file-upload">
              <label htmlFor={`file-upload-${key}`} className="p-button-warning p-button m-0 w-100">
                <i className="pi pi-cloud-upload"></i> Seleccionar archivo
              </label>
              <input
                id={`file-upload-${key}`}
                type="file"
                style={{ display: "none" }}
                // onChange={(e) =>
                //   this.setState({ [key]: e.target.files[0] })
                // }
				onChange={e => this.uploadFileReport(e)}
                chooseLabel="Seleccionar Archivo"
              />
            </div>
          }
          {this.state[key]?.name && 
            <div className="file-info d-flex justify-content-between">
              <div className="label-info">
                {this.state[key].name}
              </div>
              <div className="action-info">
                <i
                  onClick={() => {
                    this.setState({ [key]: null });
                  }}
                  className="pi pi-trash"
                  style={{ fontSize: "1.5em", cursor: "pointer" }}
                ></i>
                </div>                            
            </div>
          }
        </div>
      </div>
    )
  }
	render() {
		return (
			<div className="container">
				<Panel header="Checklist Despacho">
				<Toast ref={(el) => this.growl = el} />
				<Row>
					<Col xs="12" md="4">
						<div className="form-group">
							<label>Folio</label>
							<input type="text"  value={this.state.folio}  placeholder="Sin asignar" readOnly onChange={e => this.setState({folio : e.target.value})} className="form-control"/>
						</div>
					</Col>
					<Col xs="12" md="4">
						<div className="form-group">
							<label className="d-block">Fecha</label>
							<PCalendar
								label={"fecha"}
								getCommonDataFromChilds={this.getCommonDataFromChilds}
								showIcon
								value={this.state.fecha}
							/>
						</div>
					</Col>
					<Col xs="12" md="4">
						<div className="form-group">
							<label className="d-block">Hora</label>
							<PTimer
								label={"hora"}
								getCommonDataFromChilds={this.getCommonDataFromChilds}
								showIcon
							/>
						</div>
					</Col>
				</Row>
				<Row>
					<Col xs="12" md="6">
						<div className="form-group">
							<label>Número de unidad</label><br/>
							<Select options={this.state.unidades}  onChange={e =>	this.setState({num_unidad: e.value}) }  placeholder="Elegir unidad"/>
						</div>
					</Col>
					<Col xs="12" md="6">
						<div className="form-group">
							<label>Número de Remolque</label><br/>
							<Select options={this.state.remolques}  onChange={e =>	this.setState({num_remolque: e.value}) }  placeholder="Elegir remolque"/>
						</div>
					</Col>
				</Row>
				{/* <Row>
					<Col xs="12" md="6">
						{this.getImageInput("Evidencia (foto-video)", "foto_id")}
					</Col>
				</Row> */}
				<div className="checklist-despacha-fieldset">
					<Fieldset legend="Documentos Operador">
						{this.listadoDocumentosOperador.bind(this)()}
					</Fieldset>
					<Fieldset legend="Documentos de la Unidad">
						{this.listadoDocumentosUnidad.bind(this)(0)}
					</Fieldset>
					<Fieldset legend="Documentos del remolque">
						{this.listadoDocumentosUnidad.bind(this)(1)}
					</Fieldset>
					<Fieldset legend="Equipo de Seguridad (EPP) e Higiene">
						{this.listadoSeguridad.bind(this)()}
					</Fieldset>
					<Fieldset legend="Equipo de Sujeción">
						{this.listadoSujecion.bind(this)()}
					</Fieldset>
				</div>
				<Row>
					<Col xs="12">
						<div className="form-group">
							<label>Comentarios</label><br/>
							<input type="text"  value={this.state.comentarios} onChange={e => this.setState({comentarios : e.target.value})} className="form-control"/>
						</div>
					</Col>
					<Col className="text-center" xs="12">
						<Button className="p-button-warning" onClick={this.handleSubmit} >Guardar</Button>
					</Col>
				</Row>
			</Panel>
			</div>
		);
	}

}

export default InspeccionVigilancia;
